import React, { useContext } from "react";
import PropTypes from "prop-types";
import UnitLayout from "components/unit-layout";
import UnitDetails from "components/database/unit-details";
import { LocaleContext } from "context/locale-context";
import useDataApi from "hooks/useDataApi";
import { ThemeContext } from "context/theme-context";
import Skeleton from "react-loading-skeleton";
// This will be used in next few weeks to make a generalised observation Details for all sites, override it for now
function UnitWrapper({ id, location }) {
  const locale = useContext(LocaleContext);
  const { apiAuthHeader, apiUrl } = useContext(ThemeContext);
  const [{ data, isLoading, isError }, doFetch] = useDataApi(
    `${apiUrl}/syria/${locale}/media/${id}`,
    [],
    apiAuthHeader
  );
  // get observation details and pass on to the details component
  return (
    <>
      {isLoading ? (
        <div
          css={{
            margin: "5rem",
          }}
        >
          <Skeleton count={10} />
        </div>
      ) : (
        <UnitLayout
          location={{
            state: {
              from:
                location?.state?.from === "data-archive"
                  ? location?.state?.from
                  : `incident?id=${data.incident}`,
            },
          }}
          isModal={!!location?.state?.from || false}
        >
          <UnitDetails
            unit={data}
            locale={locale}
            isModal={!!location?.state?.from || false}
          />
        </UnitLayout>
      )}
    </>
  );
}

UnitWrapper.propTypes = {
  id: PropTypes.object,
  location: PropTypes.object,
};

export default UnitWrapper;
